@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Didact+Gothic');

// Set your brand colors
$red: #C44741;
$green: rgb(143, 177, 88);
$brown: rgb(70, 61, 52);
$beige-light: rgb(170, 166, 150);
$beige-lighter: #FFF;

// Update Bulma's global variables
$family-sans-serif: 'Didact Gothic', sans-serif;

$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $red;
$link: $green;
$link-hover: $beige-light;
$widescreen-enabled: true;
$fullhd-enabled: true;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;


$navbar-item-color: $beige-light;
$navbar-background-color: $brown;
$navbar-item-img-max-height: 2rem;
$modal-background-background-color: rgba($brown, 0.4);
// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/all.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";
@import "../node_modules/bulma/sass/grid/tiles.sass";
@import "../node_modules/bulma/sass/components/modal.sass";
@import "../node_modules/bulma/sass/components/level.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/components/card.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/footer.sass";

#logo { padding-top: 8px;}
main { padding-top: 40px }
.grid-item {
  width: 200px;
  padding: 10px 15px;
  border: 1px solid $grey-light;
  border-radius: 25px;
  margin: 10px;
}
.pawn-container {
  .pawn-name {
    text-align: center;
  }

  button {
    border: none;
    padding: 0 5px;
    cursor: pointer;
    i {
      color: $green;
    }
  }

  .pawn-ui {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey-light;
    margin: 0 0 10px 0;
    padding: 0 0 5px 0;

    .print-queue-controls {
      display: table-cell;
    }

    .pawn-action-buttons {
      display: table-cell;
    }
  }
}

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 200%;
  height: 200%;
  border-radius: 50%;
  background-color: $link;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}